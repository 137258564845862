import * as React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import Content from "../components/Content"
import Footer from "../components/Footer"
import "../styles/index.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Lapsi" />
    <Hero />
    <Content />
    <Footer />
  </Layout>
)

export default IndexPage
