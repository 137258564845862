import React from 'react';
import { Link } from "gatsby";

const ArticleCard = ({name, color, bgColor, img}) => {
  return (
    <div 
      className="article-card__wrapper" 
      style={{ backgroundImage: `url(${img})`, backgroundColor: `${bgColor}` }}
    >
      <Link to={`/category/${name}`} className="link-route">
        <div
          className="article-card__label"
          style={{backgroundColor: `${color}`}}
        >
          <span>{name}</span>
        </div>
      </Link>
      
    </div>
  );
}

export default ArticleCard;
