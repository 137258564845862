import React from "react"
import Header from "src/components/Header"

const Layout = ({ children }) => {
  return (
      <div className="layout-container">
        <Header />
        <main>{children}</main>
      </div>
  )
}

export default Layout
