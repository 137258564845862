import React, { useEffect, useRef } from "react"
import ButtonAppStore from "src/components/ButtonAppStore";
// TODO: Subscribing. Delete one comite or refactore code
// import SubscriberField from "src/components/SubscriberField"
import bg from "src/images/hero_image.png";
import baby from "src/images/baby_with_bottle.svg"

const Hero = () => {
  const subscribeFormRef = useRef(null);

  useEffect(() => {
    const subscribeForm = subscribeFormRef?.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        const element = document.getElementById("header-sticky-btn")
        if (!e.isIntersecting) {
          element?.classList.add("show");
          element?.classList.remove("hide");
        } else {
          element?.classList.add("hide");
          element?.classList.remove("show");
        }
      },
      { threshold: [1] }
    );

    if (subscribeForm) {
      observer.observe(subscribeForm)
    }
    return (() => {
      observer.unobserve(subscribeForm)
    })

  }, [subscribeFormRef]);

  return (
    <section className="container hero__wrapper">
      <div className="hero">
        <div className="hero__content">
          <h2 className="content-title hero__content-title">
            <span>Kids don't come with a manual.</span>
            <span>But we've tried to make one ourselves</span>
          </h2>
          <p className="content-text hero__content-text">
            <span>
              The only tool you need for your new full-time parent job
            </span>
          </p>

          <div ref={subscribeFormRef}>
            <ButtonAppStore />
          </div>

          {/* // TODO: Subscribing. Delete one comite or refactore code */}
          {/* <div ref={subscribeFormRef}>
            <div className="subscribe-form">
              <SubscriberField placeholder="Enter email" nameButton="I'm in" />
            </div>
            <div className="subscribe-form__small mb-4">
              <img src={baby} alt="baby-img" />
              <SubscriberField
                placeholder="Enter email"
                nameButton="I'm in"
                type="column"
              />
            </div>
          </div> */}
        </div>

        <div className="hero__bg-img">
          <img src={bg} alt="bg-img" />
        </div>

        <div className="hero__baby-img--phone">
          <img src={baby} alt="baby-img" />
        </div>         
      </div>
    </section>
  )
}

export default Hero
