import React, { useRef, useState } from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import checkMark from "src/images/check_mark.svg"

const validateEmail = (inputText) => {
  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  if (inputText.match(mailformat)) {
    return true
  } else {
    return false
  }
}

const SubscriberField = ({
  placeholder,
  nameButton,
  type = "row",
  bgInput = true,
}) => {
  const [email, setEmail] = useState("")
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [checkbox, setCheckbox] = useState(false)

  const inputRef = useRef(null)

  const handleEmailValue = (e) => {
    if (e.target.value === "") {
      setErrorMessage('')
    }
    setEmail(e.target.value)
    setIsSubscribed(false)
  }
  
  const handleCheckbox = (e) => {
    setCheckbox( e.target.checked)
  };

  const sendEmailValue = () => {
    if(!checkbox) {
      return setErrorMessage("Confirm agreement, please")
    }
    const isValidEmail = validateEmail(email)
    if (isValidEmail) {
      addToMailchimp(email)
      .then(data => {
        if(data?.result === "success") {
          setIsSubscribed(true)
          setEmail("")
          setErrorMessage('')
        } else {
          if(data?.msg?.includes('has too many recent signup requests')) {
            return setErrorMessage('Recipient has too many recent signup requests.')
          }
          setErrorMessage('Sorry. Please try again.')
        }
      })
    } else {
      inputRef.current.focus()
      setErrorMessage("Please enter correct email.")
    }
  }

  return (
    <>
      {type === "row" ? (
        <>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control form-control-padding"
              placeholder={placeholder}
              aria-label={placeholder}
              aria-describedby="basic-addon"
              onChange={handleEmailValue}
              value={email}
              ref={inputRef}
            />
            {!!errorMessage && (
              <span className="input-group__error">{errorMessage}</span>
            )}

            <div className="input-group-append">
              <button
                className="btn btn-input-group"
                type="button"
                onClick={sendEmailValue}
              >
                {!isSubscribed ? nameButton : <img src={checkMark} alt="check-mark" />}
              </button>
            </div>
          </div>
          <div>
            <input type="checkbox" className="checkbox-input" onChange={handleCheckbox} />
            <label className="checkbox-label">
              I have read and agree with the&nbsp;
              <a 
                className="text-link" 
                href="https://www.notion.so/Terms-and-Conditions-Lapsi-App-09246b4084a643d9b9c73458703b90aa"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
              &nbsp;and&nbsp;
              <a 
                className="text-link"
                href="https://www.notion.so/aknaham/Privacy-Policy-Lapsi-LLC-69e575acd4894127ad092be829203b52"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </label>
          </div>
        </>
      ) : (
        <div className="input-group-column__wrapper">
          <div className="input-group-column mb-3">
            <input
              type="text"
              className={`form-control ${
                !bgInput ? "form-control-light" : ""
              } ${!errorMessage && "mb-3"}`}
              placeholder={placeholder}
              aria-label={placeholder}
              aria-describedby="basic-addon"
              onChange={handleEmailValue}
              value={email}
              ref={inputRef}
            />
            {!!errorMessage && (
              <span className="mb-3 input-group__error">{errorMessage}</span>
            )}
            <button
              className="btn btn-input-group-column"
              type="button"
              onClick={sendEmailValue}
            >
              {!isSubscribed ? nameButton : <img src={checkMark} alt="check-mark" />}
            </button>
          </div>
          <div>
            <input type="checkbox" className="checkbox-input" onChange={handleCheckbox} />
            <label className="checkbox-label">
              <span>
                I have read and agree with the&nbsp;
                <a 
                  className="text-link"
                  href="https://www.notion.so/Terms-and-Conditions-Lapsi-App-09246b4084a643d9b9c73458703b90aa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
                &nbsp;and&nbsp;
                <a 
                  className="text-link"
                  href="https://www.notion.so/aknaham/Privacy-Policy-Lapsi-LLC-69e575acd4894127ad092be829203b52"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </span>
            </label>
          </div>
        </div>
      )}
    </>
  )
}

export default SubscriberField
