import React from "react";
import { Link } from "gatsby";
import SubscriberField from "./SubscriberField";
import Card from "src/components/Card";
// import Game from "src/components/Game";
import ArticleCard from 'src/components/ArticleCard';
import handIcon from "src/images/yellow_hand.png";
import smartFeed from "src/images/card_smart_feed.svg";
import library from "src/images/card_library.svg";
import milestones from "src/images/card_milestones.svg";
import tools from "src/images/card_tools.svg";
import account from "src/images/card_account.svg";

import feedingCategory from "src/images/catergory/feeding_bg_category.svg";
import communicationCategory from "src/images/catergory/communication_bg_category.svg";
import playtimeCategory from "src/images/catergory/playtime_bg_category.svg";
import learningCategory from "src/images/catergory/learning_bg_category.svg";
import movementCategory from "src/images/catergory/movement_bg_category.svg";
import familyCategory from "src/images/catergory/family_bg_category.svg";

const articleCategories = [
  {
    name: 'Feeding',
    color: '#F7C51D',
    bgColor: '#F57F12',
    img: feedingCategory,
  },
  {
    name: 'Communication',
    color: '#6AB6FF',
    bgColor: '#9ACEFF',
    img: communicationCategory,
  },
  {
    name: 'Playtime',
    color: '#1153FC',
    bgColor: '#3F75FF',
    img: playtimeCategory,
  },
  {
    name: 'Learning',
    color: '#9C8CDE',
    bgColor: '#7764C4',
    img: learningCategory,
  },
  {
    name: 'Movement',
    color: '#F4C038',
    bgColor: '#FBD164',
    img: movementCategory,
  },
  {
    name: 'Family',
    color: '#CE4444',
    bgColor: '#E96051',
    img: familyCategory,
  }
]

const Content = () => (
  <>
    {/* parent-info block */}
    <section className="parent-info__wrapper">
      <div className="container parent-info">
        <div className="parent-info__inner">
          <div className="parent-info__content">
            <h2 className="content-title parent-info__content-title mb-4">
              Made for parents by parents
            </h2>
            <p className="content-text parent-info__content-text">
              <span className="mb-3">
                There are tons of websites, apps and YouTube channels to tell
                us how to be a great parent and how to help our little ones
                grow. And an even bigger variety of useful tools to ease the
                path, both online and offline.
              </span>
              <span className="mb-3">
                We started Lapsi in an online chat, where we shared our pain
                of using all those parenting apps and online tools.
              </span>
              <span>
                With lots of ideas and toddler cheerleader team support, we've
                decided that it's time to make an app to fit all needs.
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>

    {/* tools-info block */}
    <section className="tools-info__wrapper">
      <div className="container tools-info">
        <div className="tools-info__inner">
          <div className="tools-info__content">
            <h2 className="content-title tools-info__content-title mb-4">
              Ultimate parental tool right in your pocket
            </h2>
            <p className="content-text tools-info__content-text">
              Lapsi is a combination of a parenting book and a digital toolbox
              to help you with your parenting job
            </p>
            <div className="tools-info__cards">
              <Card
                img={smartFeed}
                imgTitle="smart-feed-img"
                title="Smart Feed"
                text="All you need comes to your personalized Smart Feed to show you content and tools exactly when you need them"
              />
              <Card
                img={library}
                imgTitle="content-library-img"
                title="Content Library"
                text="You can also surf through the Library for articles, videos, podcasts 
                  and much more for any kid age 
                  and topic"
              />
              <Card
                img={milestones}
                imgTitle="milestones-img"
                title="Milestones"
                text="We also included easy and informative milestones tracker, connected to WHO data, so you always know what to expect from your little one"
              />
              <Card
                img={tools}
                imgTitle="parenting-tools-img"
                title="Parenting Tools"
                text="Sleep Sounds? Feeding Tracker? Vaccination Calendar? Drug Wiki? Family To-Do List? Yeap, we've put all this and even more into Lapsi"
              />
              <Card
                img={account}
                imgTitle="family-account-img"
                title="Family Account"
                text="Cloud sharing and data sync for all the family members and your babysitter to be on top of what's going with your kid and not to miss any vital news"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* articles block */}
    <section className="container articles__wrapper">
      <div className="articles__content">
        <h2 className="content-title articles__content-title">
          Much about babies under one year old and family relationships
        </h2>
        <div className="articles__grid-wrapper">
          {
            articleCategories.map((category, index) => 
              <div key={index} className="articles__grid-item">
                <ArticleCard 
                  name={category.name}
                  color={category.color}
                  bgColor={category.bgColor}
                  img={category.img}
                />
              </div>
            )
          }
        </div>
        <Link className="btn articles__btn" to="/articles">
          View all
        </Link>
      </div>
    </section>

    {/* game block */}
    {/* <section className="container game__wrapper">
      <div className="game">
        <Game />
        <div className="game__content">
          <h2 className="content-title game__content-title mb-4">
            Signup for early access and get bonus at launch!
          </h2>
          <p className="content-text game__content-text mb-5">
            <span className="mb-3">
              Already want to try Lapsi? And we're glad to give you cute bonus
              - unique hand-designed Lapsi Milestones Manual to track your
              baby's development progress.
            </span>
            <span className="mb-3">
              All you need to do to get it - find all babies hiding on this
              picture. And we will send HQ file to your email!
            </span>
            <span className="mb-3">
              Click the tile to see if a baby is hidden there!
            </span>
            <span>
              And even more - if you be lucky enough to find all babies here,
              you will become a part of our Early Birds Club so you will get
              even more bonuses at launch!
            </span>
          </p>
          <img src={handIcon} alt="hand-icon" className="game__hand-img" />
        </div>
      </div>
    </section> */}

    {/* posted block */}
    <section className="posted__wrapper">
      <div className="container posted">
        <div className="posted__content">
          <h2 className="content-title mb-4">Let's be friends!</h2>
          <p className="content-text posted__content-text">
            <span>
              Signup for our newsletter to be on top of all major updates and to get access to Lapsi Special Content!
            </span>
          </p>
        </div>
        <SubscriberField
          placeholder="Enter email"
          nameButton="Keep me posted!"
          type="column"
          bgInput={false}
        />
      </div>
    </section>
  </>
);

export default Content
