import React from "react"

const Card = ({ img, imgTitle, title, text }) => {
  return (
    <div className="card">
      <img src={img} alt={imgTitle} />
      <h4 className="card-title mb-3">{title}</h4>
      <p className="card-text">{text}</p>
    </div>
  )
}

export default Card
