import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon16x16 from "src/images/favicons/favicon16x16.png";
import favicon32x32 from "src/images/favicons/favicon32x32.png";
import favicon96x96 from "src/images/favicons/favicon96x96.png";
import favicon120x120 from "src/images/favicons/favicon120x120.png";

const faviconLinks = [
  { rel: "icon", type: "image/png", sizes: "16x16", href: favicon16x16 },
  { rel: "icon", type: "image/png", sizes: "32x32", href: favicon32x32 },
  { rel: "icon", type: "image/png", sizes: "96x96", href: favicon96x96 },
  { rel: "icon", type: "image/png", sizes: "120x120", href: favicon120x120 },
];

const SEO = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
    `
    )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      link={faviconLinks}
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
