import React from 'react';
import { Link } from "gatsby";
// import ReactDOM from "react-dom";
import { useInView } from 'react-intersection-observer';

import BurgerMenu from "src/components/BurgerMenu";
import ButtonAppStore from "src/components/ButtonAppStore";
import logo from "src/images/logo.svg";

// TODO: Modal window. Delete one comite or refactore code
// import Modal from "src/components/Modal";
// import btnIcon from "src/images/envelope.svg";

const Header = () => {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  // TODO: Modal window. Delete one comite or refactore code
  // const [isOpenModal, setIsOpenModal] = useState(false)
  
  // TODO: Modal window. Delete one comite or refactore code
  // const showModal = () => setIsOpenModal(true)

  return (
    <>
      <header className="header__wrapper" ref={ref}>
        <div className="pseudo-header"></div>
        <div className={`${!inView ? "header--sticky" : "header__inner--wrapper"}`}>
          <div className={`container ${!inView ? "header__inner--sticky" : "header__inner"}`}>
            <img src={logo} alt="logo-lapsi" className="logo" />
            <div className="navigation-wrapper">
              <ul className="navigation">
                <li className="navigation__item">
                  <Link to="/articles">Articles</Link>
                </li>
              </ul>
              {
                !inView && 
                <div id="header-sticky-btn" className="hide">
                  <ButtonAppStore />

                  {/* // TODO: Modal window. Delete one comite or refactore code */}
                  {/* <button className="btn" type="button" onClick={showModal}>
                    I'm in!
                  </button>
                  <button className="btn-icon" type="button" onClick={showModal}>
                    <img src={btnIcon} alt="btn_icon"/>
                  </button> */}

                </div>
              }
            </div>
            <div className="phone-navigation">
              <BurgerMenu>
                <div className="phone-navigation__wrapper">
                  <ul className="navigation">
                    <li className="navigation__item">
                      <Link to="/">About us</Link>
                    </li>
                    <li className="navigation__item">
                      <Link to="/articles">Articles</Link>
                    </li>
                  </ul>
                  <ButtonAppStore />
                </div>
              </BurgerMenu>
            </div>
          </div>
        </div>
      </header>

      {/* // TODO: Modal window. Delete one comite or refactore code */}
      {/* {
        !!isOpenModal &&
        ReactDOM.createPortal(
          <Modal isOpen={isOpenModal} closeModal={()=>setIsOpenModal(false)}>
            <>
              <h4 className="modal-content__title mb-3">Be with us!</h4>
              <p className="modal-content__text">
                <span>We are on the final stage of development and will publish Lapsi soon to the App Store. So leave your email to get all the news about Lapsi.</span>
                <span>So leave your email to get all the news about Lapsi.</span>
              </p>
            </>
          </Modal>,
          document.body)
      } */}
    </>
  );
}

export default Header;
